export default [
  {
    key: 'lotteryId',
    label: 'field.lotteryName',
    type: '',
    cols: 3,
  },
  {
    key: 'lotteryTimeId',
    label: 'field.lotteryTime',
    type: '',
    cols: 3,
  },
  {
    key: 'post',
    label: 'field.channel',
    type: '',
    cols: 3,
  },
  {
    key: 'number',
    label: 'field.number',
    type: 'text',
    cols: 3,
  },
  {
    key: 'period',
    label: 'field.period',
    type: 'radio',
    options: [
      { text: 'field.today', value: 'today' },
      { text: 'field.yesterday', value: 'yesterday' },
      { text: 'field.thisWeek', value: 'thisWeek' },
      { text: 'field.lastWeek', value: 'lastWeek' },
      { text: 'field.thisMonth', value: 'thisMonth' },
      { text: 'field.lastMonth', value: 'lastMonth' },
      { text: 'field.other', value: 'other' },
    ],
    cols: 6,
    skip: true,
  },
  {
    key: 'startDate',
    label: 'field.fromDate',
    type: 'date',
    cols: 3,
    searchField: 'date',
    operator: 'd>=',
  },
  {
    key: 'endDate',
    label: 'field.toDate',
    type: 'date',
    cols: 3,
    searchField: 'date',
    operator: 'd<='
  },
];